module.exports = {
    language: {
        name: 'English'
    },
    index: {
        language : 'Select Language',
        ch: 'Chinese',
        en:'English',
        thai:'Thai',
        viet: 'Vietnamese',
    },
    order: {
        item :'Project Type',
        pleaseitem:'Please Select Project Type',
        chthfcl:'China-Thailand FCL',
        chthlcl:'China-Thailand LCL',
        chvfcl:'China-Vietnam FCL',
        chvlcl:'China-Vietnam LCL',
        imagedownload:'Please save the picture to download',
        attachment:'Attachment',
        downloadattachment:'Please download the attachment',
        todownload:'Click to download'
    },
    query:{
        search: 'Search',
        receipt:'Check into the warehouse receipt',
        orderinfo:'Order Information',
        ordertime:'Order Time',
        item:'Project Type',
        route:'Route',
        estimatetime:'Estimate Time',
        cargo:'GoodsNum',
        volume:'Volume',
        weight:'Gross Weight',
        charging:'Charging Weight',
        shipment :'Shipment Tracking',
        message: 'Message Prompt',
        subtitle: 'Searched Order Information Not Available',
        orderno: 'Please enter the receipt number'
    },
    contact:{
        contact: 'Contact',
        address: 'Office Address',
        tel: 'Tel',
        phone: '+86 13609615420',
        phone1: '+86 13828730543',
        hezhi: 'Shenzhen T-smart International Logistics Co., Ltd',
        hezhiaddress: 'Add: Rm 1220-1222,Tower B,Yousong Tech Building,',
        hezhiaddress2:'Longhua District, Shenzhen',
        hezhiphone: 'Tel: 0755-23158716'
    },
    about:{
        about: 'About Us',
        intro : 'Shenzhen T-smart International Logistics Co., Ltd (hereinafter referred to as "T-smart logistics") was registered and established in Shenzhen in 2012, and its subsidiaries include Hong Kong, Guangxi, Vietnam, Thailand, etc. T-smart has been committed to providing comprehensive logistics and customs solutions, bonded logistics services, and cross border transportation services for multinational factories in China; In recent years, with the transfer of global manufacturing from Chian to South Asian countries, T-smart logistcs has integrated customs brokerage, transportation resource and established operational organization, and by setting up the China-Asean logistics channel T-smart has provided stable, fast, professional logistics services to well-known manufacturing enterprises from Japan, South Korea, Hong Kong and Chian, as well as top global freight forwarders.',
        more : 'More',
        major: 'Major Service Products',
        major1: 'China-Vietnam Road Transportation (FCL & LCL)',
        major2: 'China-Thailand Road Transportation (FCL & LCL)',
        major3: 'China-Asean Air & Ocean Freight Services',
        major4: 'China mainland-Hong Kong Transportation',
        major5: 'Trade Consultation and Integrated Logistics Solutions',
        major6: 'Professional Customs Declaration and Inspection Services',
        major7: 'Design and Implementation of Integrated Logistics Customs Solution ',
        team: 'Management Team',
        team1: 'T-smart has attracted a group of professional management and skilled talents who have been engaged in the logistics industry for many years. Most of our team members have years of management and practical experience in large multinational logistics or private companies.',
        team2: 'Since 2019, we have successively introduced a group of outstanding overseas students to expand our business in the Southeast Asian market.',
        team3: 'Our management team has efficient and reliable logistics execution capabilities; At the same time, we also have the support of a team of senior logistics experts, which can ensure the provision of advanced and efficient comprehensive logistics solutions for customers.',
        team4: 'Professional logistics talents are the key to the rise of enterprises, and a stable and high-quality talent team is the cornerstone of achieving enterprise development goals.',
        team5: 'T-smart logistics currently has more than 20 employees with operating and management qualifications, including 2 with master'+'s'+' degrees, and more than 85% of the employees have a college degree or above.',
        hezhi: 'T-smart Companies',
        hezhi1: 'Shenzhen T-smart International Logistics Co., Ltd (Headquarter)',
        hezhi2: 'T-smart International Logistics (Guangxi) Co., Ltd',
        hezhi3: 'Shenzhen T-smart International Logistics Co., Ltd, Chongqing Branch Office',
        hezhi4: 'Shenzhen Herui Supply Chain Management Co., Ltd',
        hezhi5: 'T-smart International Logistics (Shanghai) Co., Ltd',
        hezhi6: 'T-smart International Logistics (Dongguan) Co., Ltd',
        hezhi7: 'T-smart Logistics (Vietnam) Co., Ltd.',
        hezhi8: 'T-smart Logistics (Thailand) Co., Ltd.',
    }
}