import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'index',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../view/index.vue')
    },
    {
        path: '/order',
        name: 'order',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../view/order.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../view/about.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../view/contact.vue')
    },
    {
        path: '/query',
        name: 'query',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../view/query.vue')
    }
]


const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, form, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    } else {
        document.title = '合智物流'
    }
    next()
})

export default router