import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/style.css";
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap,{
	ak:'O4l06EfawpY6LGT4TdAvNvkybDNfprsY'
});
import 'element-ui/lib/theme-chalk/index.css'
import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import thaiLocale from 'element-ui/lib/locale/lang/th'
import vietLocal from 'element-ui/lib/locale/lang/vi'
Vue.use(ElementUI);
Vue.config.productionTip = false

import lang from '@/lang/index'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

//从localStorage里获取用户中英文选择，没有则默认中文messages:{'zh':{...lang.zh,...zhLocale},'en':{...lang.en,...enLocale}}
const i18n=new VueI18n({locale:localStorage.getItem('languageSet')||'zh',messages:{
    'zh':{...lang.zh,...zhLocale},
    'en':{...lang.en,...enLocale},
    'thai':{...lang.thai,...thaiLocale},
    'viet':{...lang.viet,...vietLocal}
}})
ElementLocale.i18n((key, value) => i18n.t(key, value))

new Vue({
	router,
    i18n,
	render: h => h(App),
}).$mount('#app')
