module.exports = {
    language: {
        name: 'ภาษาไทย'
    },
    index: {
        language : 'เลือกภาษา',
        ch: 'ภาษาจีน',
        en:'ภาษาอังกฤษ',
        thai:'ภาษาไทย',
        viet: 'ภาษาเวียดนาม',
    },
    order: {
        item :'项目类型',
        pleaseitem:'请选择项目类型',
        chthfcl:'中泰整车',
        chthlcl:'中泰集拼',
        chvfcl:'中越整车',
        chvlcl:'中越集拼',
        imagedownload:'请保存图片下载',
        attachment:'附件',
        downloadattachment:'请下载附件',
        todownload:'คลิกเพื่อดาวน์โหลด'
    },
    query:{
        search: 'ค้นหา',
        receipt:'请输入入仓单查询',
        orderinfo:'订单信息',
        ordertime:'下单时间',
        item:'项目类型',
        route:'线路',
        estimatetime:'预计时间',
        cargo:'货物件数',
        volume:'体积',
        weight:'毛重',
        charging:'计费重量',
        shipment :'运输跟踪',
        message: '信息提示',
        subtitle: '暂无您查询的订单信息',
        orderno: '请输入入仓单号'
    },
    contact:{
        contact: '联系我们',
        address: '公司地址',
        tel: '联系电话',
        phone: '13609615420',
        phone1: '13828730543',
        hezhi: '深圳市合智国际物流有限公司',
        hezhiaddress: '地址: 深圳市龙华区油松科技大厦B栋1220-1222',
        hezhiaddress2: '',
        hezhiphone: '电话: 0755-23158716'
    },
    about:{
        about: '公司简介',
        intro : '深圳市合智国际物流公司（以下简称“合智物流”）于2012年在深圳特区登记成立，'+
        '旗下包括香港合智供应链有限公司、广西合智国际物流有限公司 、合智国际物流'+
        '（越南）有限公司等，是一家集团化、专业化的综合性物流公司。合智物流自成立以来，'+
        '一直致力于为中国的跨国工厂提供综合物 流关务解决方案、保税物流服务、跨境运输服务；'+
        '近年来，伴随着全球制造业从中国向东南亚国家转移，合智整合关务运输资源、设置运营机构，'+
        '顺势开通了中国---东盟物流通道，为日本、韩国、香港以及中国的知名制造企业以及全球顶'+
        '级货代提供稳定、快捷、专业的物流服务。',
        more : '更多介绍',
        major: '主要服务产品',
        major1: '中国-越南整车及拼箱运输服务',
        major2: '中国-泰国整车及拼箱运输服务',
        major3: '中国-东盟空运、海运货运代理服务',
        major4: '中港运输服务',
        major5: '贸易咨询和综合物流解决方案',
        major6: '专业报关报检服务',
        major7: '综合物流关务解决方案设计和实施',
        team: '公司管理团队',
        team1: '合智物流吸引了一批多年从事物流行业的专业管理和技能型人才，我们中的大多数成员都曾拥有过大型跨国物流企业或民营企业多年的管理经验和实际操作经验。',
        team2: '2019年起，我们陆续引进一批批优秀的留学人员，面向东南亚市场拓展业务。',
        team3: '我们的管理团队具备高效、可靠的物流执行能力；同时我们又拥有一个资深的物流专家团队支持，因而能够保障为客户提供先进、高效的综合物流解决方案。',
        team4: '专业的物流人才是企业崛起的关键，稳健的高素质人才队伍是实现企业发展目标的基石。',
        team5: '公司现有操作及管理以上员工20余人，其中硕士2名，85%以上人员拥有大专及以上学历。',
        hezhi: '合智公司',
        hezhi1: '深圳市合智国际物流有限公司(总部)',
        hezhi2: '广西合智国际物流有限公司',
        hezhi3: '深圳市合智国际物流有限公司(重庆)分公司',
        hezhi4: '深圳市合睿供应链管理有限公司',
        hezhi5: '上海聚智国际物流有限公司',
        hezhi6: '东莞市合智国际物流有限公司',
        hezhi7: '越南合智物流责任有限公司',
        hezhi8: '泰国合智国际物流有限公司',
    }
}